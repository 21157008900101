import { getSiteConfigData } from "@api/siteConfig";
import { SiteConfigContext } from "@context/siteConfig";
import { getCurrencyCodeBySiteCode, getSiteCode } from "@helpers/site";
import type { GetServerSidePropsContext, NextPage } from "next";
import React from "react";
import useSWR from "swr";

// -=-=-=-=- Add return type on function
const withSiteConfig = (Component: NextPage) => {
  const SiteConfigProvider = (props) => {
    const { data } = useSWR(
      [
        "siteConfigStatic",
        props.siteConfig.siteCode,
        props.siteConfig.locale,
        props.siteConfig.appType,
      ],
      (_: string, siteCode: string, locale: string, appType: string) =>
        getSiteConfigData(siteCode, locale, appType)
    );

    const siteConfig = { ...props.siteConfig, ...data };

    return (
      <SiteConfigContext.Provider value={siteConfig}>
        <Component {...props} />
      </SiteConfigContext.Provider>
    );
  };

  return SiteConfigProvider;
};

export const getSiteConfig = (
  context: GetServerSidePropsContext,
  env: string
): SiteConfig => {
  const host = context.req ? context.req.headers.host : window.location.host;
  const { locale, locales, defaultLocale } = context;
  const siteCode = getSiteCode(env, host);
  const isRtl = locale.includes("ar");
  const currencyCode = getCurrencyCodeBySiteCode(siteCode);

  const isMobile = !!(context.req
    ? context.req.headers["user-agent"]
    : navigator.userAgent
  ).match(/Android|BlackBerry|iPhone|iPod|Opera Mini|IEMobile|WPDesktop/i);

  const appType = isMobile ? "mweb" : "desktop";
  const filteredLocales = locales.filter((locale) => locale.length == 2);

  return {
    protocol: "https://",
    domain: host,
    currencyCode,
    siteCode,
    appType,
    locale: locale.includes("ar") ? "ar" : "en",
    locales: filteredLocales,
    defaultLocale,
    env,
    isRtl,
    isMobile,
  };
};

export default withSiteConfig;
