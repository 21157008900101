import { sites } from "@helpers/sites";
import { NextRouter } from "next/router";

const DEFAULT_SITE_CODE = "US";

const getDefaultLocaleBySiteCode = (siteCode: string): string => {
  return sites[siteCode].defaultLocale;
};

export const getLocaleInUrl = (
  defaultLocale: string,
  locale: string
): string => {
  return !defaultLocale.includes(locale) ? `/${locale}` : "";
};

export const getLocaleInUrlBySiteCodeAndLocale = (
  siteCode: string,
  locale: string
): string => {
  return getDefaultLocaleBySiteCode(siteCode) !== locale ? `/${locale}` : "";
};

export const getPathname = (router: NextRouter, page?: string): string => {
  switch (page) {
    case "store":
    case "store-with-cashback":
      return `/store/${router.query.storeSlug}`;
    case "category":
      return `/category/${router.query.categorySlug}`;
    case "coupons":
      return `/coupons/${router.query.categorySlug}`;
    default:
      return router.pathname === "/" ? "" : router.pathname;
  }
};

export const getSiteCodes = (): string[] => {
  return Object.keys(sites);
};

export const getDomainBySiteCode = (env: string, siteCode: string): string => {
  return sites[siteCode]["domain"][env] || "";
};

export const getSiteCode = (env: string, host: string): string => {
  let siteCode: string = DEFAULT_SITE_CODE;

  switch (env) {
    case "production":
      if (/shopcash.ae/i.test(host)) {
        siteCode = "AE";
      } else if (/sa.shopcash.com/i.test(host) || /shopcash.sa/i.test(host)) {
        siteCode = "SA";
      } else if (/eg.shopcash.com/i.test(host)) {
        siteCode = "EG";
      } else if (/kw.shopcash.com/i.test(host)) {
        siteCode = "KW";
      } else if (/om.shopcash.com/i.test(host)) {
        siteCode = "OM";
      } else if (/qa.shopcash.com/i.test(host)) {
        siteCode = "QA";
      } else if (/bh.shopcash.com/i.test(host)) {
        siteCode = "BH";
      }
      break;
    case "staging":
      if (/(^[A-Za-z]{2}.shopcashstaging.com)/i.test(host)) {
        siteCode = host.substring(0, 2).toUpperCase();
      }
      break;
    case "development":
      if (/(^[A-Za-z]{2}.shopcashdev.com:\d{1,}$)/i.test(host)) {
        siteCode = host.substring(0, 2).toUpperCase();
      }
      break;
  }

  return siteCode;
};

export const getSiteNameBySiteCode = (siteCode: string): string => {
  return sites[siteCode]["siteName"] || "";
};

// A SHORT-LIVED helper to get currency code by sitecode.
// Will replace this once we implement site-config API call in build time.
export const getCurrencyCodeBySiteCode = (siteCode: string): string => {
  switch (siteCode) {
    case "AE":
      return "AED";
    case "SA":
      return "SAR";
    case "US":
      return "USD";
    case "EG":
      return "EGP";
    case "KW":
      return "KWD";
    case "OM":
      return "OMR";
    case "QA":
      return "QAR";
    case "BH":
      return "BHD";

    default:
      return "";
  }
};
