import { StoreType } from "@shopcashTypes/storeType";

export const mapStoreType = (storeType) => {
  switch (storeType) {
    case StoreType.All:
      return "All";
      break;
    case StoreType.Deals:
      return "Deals";
      break;

    default:
      return "";
      break;
  }
};
