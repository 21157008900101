import LoadingIndicator from "@components/_shared/LoadingIndicator";
import { VariantButton } from "@shopcashTypes/variantButton";
import clsx from "clsx";
import styles from "./Button.module.scss";

const buttonsWithLoaders = [
  VariantButton.Primary,
  VariantButton.Secondary,
  VariantButton.OutlineSecondary,
];
interface ButtonProps {
  children: React.ReactNode;
  variant: VariantButton;
  large?: boolean;
  fullWidth?: boolean;
  disabled?: boolean;
  loading?: boolean;
  onClick?: () => void;
  textAlignStart?: boolean;
  rtl?: boolean;
}

const Button: React.FC<ButtonProps> = ({
  children,
  variant,
  large,
  fullWidth,
  disabled,
  onClick,
  loading,
  textAlignStart,
  rtl,
}) => {
  const handleOnButtonClick = (disabled: boolean): void => {
    if (disabled || loading) return;
    if (onClick) onClick();
  };

  const showLoader = loading && buttonsWithLoaders.includes(variant);
  return (
    <button
      className={clsx(
        styles.button,
        variant && styles[variant],
        large && styles.large,
        fullWidth && styles.fullWidth,
        disabled && styles.disabled,
        textAlignStart && styles.textAlignStart,
        rtl && styles.rtl
      )}
      disabled={disabled}
      onClick={() => handleOnButtonClick(disabled)}
    >
      {showLoader && <LoadingIndicator />}
      <div className={clsx(styles.content, loading && styles.hidden)}>
        {children}
      </div>
      {variant === VariantButton.PrimaryFolded && (
        <div className={styles.dashedBorder}></div>
      )}
    </button>
  );
};

export default Button;
