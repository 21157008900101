import Image, { ImageLoaderProps, ImageProps } from "next/image";
import styles from "./NextImage.module.scss";

interface NextImageWrapperProps {
  flipped?: boolean;
  src: string;
}

const NextImageComponentWrapper: React.FC<
  ImageProps & NextImageWrapperProps
> = ({ flipped = false, src, ...props }) => {
  if (!src) return null;
  return (
    <Image
      className={flipped ? styles.flipped : null}
      loader={nextImageLoader}
      src={src}
      {...props}
    />
  );
};

export const nextImageLoader = ({
  src,
  width,
  quality,
}: ImageLoaderProps): string => {
  const imageAssetsUrlRegEx = /^(https:\/\/zen\.shopcash\.com\/)(assets\/.+\.(?:jpg|jpeg|png|webp|gif))$/;
  const match = src.match(imageAssetsUrlRegEx);
  let transformation: string;
  if (width) {
    transformation = "width=" + width;
  }
  if (quality) {
    transformation = width
      ? transformation + ",quality=" + quality
      : "quality=" + quality;
  }

  return match == null
    ? src
    : match[1] + "cdn-cgi/image/" + transformation + "/" + match[2];
};

export default NextImageComponentWrapper;
