import { TranslationsContext } from "@context/translations";
import type { NextPage } from "next";

const LOCALES: string[] = ["en", "ar"];

const translateWithParams: Translate = (
  translation: string,
  params: (string | number)[]
) => {
  let newTranslation = translation;
  const escapeRegExp = (value: string): string => {
    return value.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
  };

  params.forEach((param: string | number, index: number) => {
    const replacement = new RegExp(escapeRegExp(`{${index}}`), "g");

    newTranslation = newTranslation.replace(replacement, param?.toString());
  });

  return newTranslation.trim();
};

const getTranslateFunction = (translations: Translations): Translate => {
  const translate: Translate = (
    key: string,
    params: (string | number)[] = []
  ): string => {
    const translation = translations[key] || "";

    if (params.length) {
      return translateWithParams(translation, params);
    }

    return translation;
  };

  return translate;
};

// -=-=-=-=- Add return type on function
const withTranslations = (Component: NextPage) => {
  const withTranslations = (props) => {
    const cloneProps = JSON.parse(JSON.stringify(props));
    delete cloneProps.translations;

    return (
      <TranslationsContext.Provider
        value={getTranslateFunction(props.translations)}
      >
        <Component {...cloneProps} />
      </TranslationsContext.Provider>
    );
  };

  withTranslations.displayName =
    Component.displayName || Component.name || "withTranslations";

  return withTranslations;
};

export const getTranslations = async (
  siteConfig: SiteConfig
): Promise<Translations> => {
  let locale = "en";

  if (LOCALES.includes(siteConfig.locale)) {
    locale = siteConfig.locale;
  }

  const translations = (await import(`../translations/${locale}.json`)).default;
  return translations;
};

export default withTranslations;
