//SPREADSHEET: https://docs.google.com/spreadsheets/d/1hxZgipMzcQvqp0weP6V--tlB1XylFO_rMxld64YcQEo/edit#gid=836836455

const formatZeroBasedIndex = (index: number) => {
  return (index + 1).toString();
};

import { DealCollectionType } from "@shopcashTypes/dealCollectionType";
import { GenzoAuthMethod } from "@shopcashTypes/genzo/authMethod";
import { GenzoDealCouponModalAction } from "@shopcashTypes/genzoDealCouponModalAction";
import { SearchResultType } from "@shopcashTypes/searchResultType";
//HOMEPAGE EVENTS
import { StoreDetails } from "@shopcashTypes/storeDetails";
import { WithdrawalMethod } from "@shopcashTypes/withdrawalMethod";

export const getBannerCollectionClickEvent = (
  bannerId: Banner["id"],
  index: number,
  collectionType: string
): GenzoEvent => {
  const valueObj = {
    banner_collection: collectionType.replace("-", "_"),
    banner: bannerId,
  };

  return {
    category: "home",
    object: "banner_collections",
    action: formatZeroBasedIndex(index),
    value: JSON.stringify(valueObj),
  };
};

export const getTrendingStoreEvent = (
  store: StoreSummary,
  index: number
): GenzoEvent => {
  return {
    category: "home",
    object: "trending_stores",
    action: formatZeroBasedIndex(index),
    value: store.id,
  };
};

export const getCategoryClickEvent = (
  category: Category,
  index: number
): GenzoEvent => {
  return {
    category: "home",
    object: "category_list",
    action: formatZeroBasedIndex(index),
    value: category.id,
  };
};

export const getCategorySeeAllClickEvent = (): GenzoEvent => {
  return {
    category: "home",
    object: "category_list",
    action: "see_all",
    value: "",
  };
};

export const getCategoryStoreEvent = (
  store: StoreSummary,
  index: number,
  categoryId: string
): GenzoEvent => {
  const valueObj = {
    store: store.id,
    category: categoryId,
  };

  return {
    category: "home",
    object: "category_list_stores",
    action: formatZeroBasedIndex(index),
    value: JSON.stringify(valueObj),
  };
};

export const getCategoryPageCategoryClickEvent = (
  categoryId: string,
  index: number
): GenzoEvent => {
  return {
    category: "category",
    object: "groups",
    action: formatZeroBasedIndex(index),
    value: categoryId,
  };
};

export const getCategoryPageStoreClickEvent = (
  categoryId: string,
  storeId: string,
  storeIndex: number
): GenzoEvent => {
  const valueObj = { category: categoryId, store: storeId };

  return {
    category: "category",
    object: "stores",
    action: formatZeroBasedIndex(storeIndex),
    value: JSON.stringify(valueObj),
  };
};

export const getDealCollectionSeeAllClickEvent = (
  collectionType: string
): GenzoEvent => {
  const valueObj = {
    deal_collection: collectionType.replace("-", "_"),
    cashback: null,
    deal: null,
    click: null,
  };

  return {
    category: "home",
    object: "deal_collections",
    action: "see_all",
    value: JSON.stringify(valueObj),
  };
};

export const getDealCollectionClickEvent = (
  deal: StoreDeal,
  index: number,
  collectionType: string,
  clickElement: string
): GenzoEvent => {
  const valueObj = {
    deal_collection: collectionType.replace("-", "_"),
    cashback: !!deal.store.featuredCashback,
    deal: deal.id,
    click: clickElement,
  };

  return {
    category: "home",
    object: "deal_collections",
    action: formatZeroBasedIndex(index),
    value: JSON.stringify(valueObj),
  };
};

export const getDealCouponModalClickEvent = (
  categoryValue: string,
  objectValue: string,
  dealId: Banner["id"],
  cashbackLabel: boolean,
  actionLabel: string,
  collectionType?: string
): GenzoEvent => {
  const valueObj = {
    cashback: cashbackLabel,
    deal: dealId,
    click: actionLabel,
  };

  if (collectionType) {
    valueObj["deal_collection"] = collectionType;
  }

  return {
    category: categoryValue,
    object: objectValue,
    action: "modal_click",
    value: JSON.stringify(valueObj),
  };
};

export const getDealShopNowClickEvent = (dealId: Banner["id"]): GenzoEvent => {
  return {
    category: "home",
    object: "deal_collections",
    action: "coupon_shop_now",
    value: dealId,
  };
};

export const getRecentlyViewedClickEvent = (
  store: StoreView,
  index: number
): GenzoEvent => {
  return {
    category: "home",
    object: "recent",
    action: formatZeroBasedIndex(index),
    value: store.id,
  };
};

export const getStoreCollectionClickEvent = (
  collectionId: StoreCollection["id"],
  storeId: StoreSummary["id"],
  index: number
): GenzoEvent => {
  const valueObj = {
    store_collection: collectionId,
    store: storeId,
  };
  return {
    category: "home",
    object: "store_collections",
    action: formatZeroBasedIndex(index),
    value: JSON.stringify(valueObj),
  };
};

export const getAllDealItemClickEvent = (
  deal: StoreDeal,
  index: number,
  page = "home"
): GenzoEvent => {
  const valueObj = {
    cashback: !!deal.store.featuredCashback,
    has_code: !!deal.couponCode,
    deal: deal.id,
  };

  return {
    category: page,
    object: "all_deals",
    action: formatZeroBasedIndex(index),
    value: JSON.stringify(valueObj),
  };
};

export const getAllDealLoadMoreClickEvent = (
  clickCounter: number,
  page = "home"
): GenzoEvent => {
  return {
    category: page,
    object: "all_deals",
    action: "load_more",
    value: clickCounter.toString(),
  };
};

export const getDiscoverStoresClickEvent = (
  store: StoreSummary,
  index: number,
  page = "home"
): GenzoEvent => {
  return {
    category: page,
    object: "discover_stores",
    action: formatZeroBasedIndex(index),
    value: store.id,
  };
};

export const getUserEducationVideoButtonClickEvent = (
  videoUrl: string
): GenzoEvent => {
  return {
    category: "home",
    object: "user_education",
    action: "click",
    value: videoUrl,
  };
};

// STORE PAGE

export const getVisitStoreEvent = (storeId: StoreDetails["id"]): GenzoEvent => {
  return {
    category: "store",
    object: "visit_store",
    action: "click",
    value: storeId,
  };
};

export const getShopNowEvent = (storeId: StoreDetails["id"]): GenzoEvent => {
  return {
    category: "store",
    object: "shop_now",
    action: "click",
    value: storeId,
  };
};

export const getDealEvent = (dealId: StoreDeal["id"]): GenzoEvent => {
  return {
    category: "store",
    object: "get_deal",
    action: "click",
    value: dealId,
  };
};

export const getGetCodeEvent = (dealId: StoreDeal["id"]): GenzoEvent => {
  return {
    category: "store",
    object: "code_get",
    action: "click",
    value: dealId,
  };
};

// LOGIN

export const getHeaderSignInEvent = (): GenzoEvent => {
  return {
    category: "login",
    object: "header_sign_in",
    action: "click",
    value: "login",
  };
};
export const getHeaderSignUpEvent = (): GenzoEvent => {
  return {
    category: "login",
    object: "header_sign_up",
    action: "click",
    value: "login",
  };
};

export const getEmailEnteredEvent = (): GenzoEvent => {
  return {
    category: "login",
    object: "email",
    action: "click",
    value: "email",
  };
};

export const getEmailVerificationEvent = (): GenzoEvent => {
  return {
    category: "login",
    object: "email",
    action: "click",
    value: "email_verification",
  };
};

export const getEmailVerificationStatusEvent = (
  success: boolean
): GenzoEvent => {
  return {
    category: "login",
    object: "email_verification",
    action: "click",
    value: success ? "success" : "failure",
  };
};

export const getReturningPasswordEvent = (): GenzoEvent => {
  return {
    category: "login",
    object: "email",
    action: "click",
    value: "returning_password",
  };
};

export const getResetPasswordRequestEvent = (): GenzoEvent => {
  return {
    category: "login",
    object: "email",
    action: "click",
    value: "reset_password_request",
  };
};

export const getResetPasswordSubmitEvent = (): GenzoEvent => {
  return {
    category: "login",
    object: "email",
    action: "click",
    value: "reset_password_submit",
  };
};

export const getResetPasswordConfirmEvent = (): GenzoEvent => {
  return {
    category: "login",
    object: "email",
    action: "click",
    value: "reset_password_confirm",
  };
};

export const getResetPasswordStatusEvent = (success: boolean): GenzoEvent => {
  return {
    category: "login",
    object: "reset_password",
    action: "status",
    value: success ? "success" : "failure",
  };
};

export const getNewPasswordEvent = (repeat = false): GenzoEvent => {
  return {
    category: "login",
    object: "email",
    action: "click",
    value: repeat ? "repeat_password" : "new_password",
  };
};

export const getPasswordVisbilityEvent = (visible: boolean): GenzoEvent => {
  return {
    category: "login",
    object: "email_password_visiblity",
    action: "status",
    value: visible ? "visible" : "hidden",
  };
};

export const getSubmitSignUpEvent = (): GenzoEvent => {
  return {
    category: "login",
    object: "email",
    action: "click",
    value: "signup",
  };
};

export const getSignUpSuccessEvent = (): GenzoEvent => {
  return {
    category: "login",
    object: "email_signup",
    action: "click",
    value: "success",
  };
};

export const getSignUpFailedEvent = (): GenzoEvent => {
  return {
    category: "login",
    object: "email_signup",
    action: "click",
    value: "failure",
  };
};

export const getOtpRequestEvent = (): GenzoEvent => {
  return {
    category: "login",
    object: "email",
    action: "click",
    value: "otp_request",
  };
};

export const getOtpResendEvent = (): GenzoEvent => {
  return {
    category: "login",
    object: "email",
    action: "click",
    value: "otp_resend",
  };
};

export const getOtpVerifyEvent = (): GenzoEvent => {
  return {
    category: "login",
    object: "email",
    action: "click",
    value: "otp_verify",
  };
};

export const getOtpVerifyStatusEvent = (success: boolean): GenzoEvent => {
  return {
    category: "login",
    object: "otp_verify",
    action: "status",
    value: success ? "success" : "failure",
  };
};

export const getLoginSignInEvent = (
  method: GenzoAuthMethod,
  isOnExtensionPage: boolean
): GenzoEvent => {
  return {
    category: isOnExtensionPage ? "extension" : "login",
    object: "sign_in",
    action: "click",
    value: method,
  };
};

export const getLoginSignUpEvent = (method: GenzoAuthMethod): GenzoEvent => {
  return {
    category: "login",
    object: "sign_up",
    action: "click",
    value: method,
  };
};

export const getLoginGoogleStatusEvent = (success: boolean): GenzoEvent => {
  return {
    category: "login",
    object: "google_auth_provider",
    action: "click",
    value: success ? "success" : "failure",
  };
};

export const getLoginFacebookStatusEvent = (success: boolean): GenzoEvent => {
  return {
    category: "login",
    object: "facebook_auth_provider",
    action: "click",
    value: success ? "success" : "failure",
  };
};

export const getLoginEmailStatusEvent = (success: boolean): GenzoEvent => {
  return {
    category: "login",
    object: "email_password_login",
    action: "click",
    value: success ? "success" : "failure",
  };
};

export const getReferralCodeSubmitEvent = (): GenzoEvent => {
  return {
    category: "login",
    object: "referral",
    action: "click",
    value: "submit_code",
  };
};

export const getReferralCodeStatusEvent = (success: boolean): GenzoEvent => {
  return {
    category: "login",
    object: "referral",
    action: "status",
    value: success ? "success" : "failure",
  };
};

export const getPrivacyPolicyEvent = (): GenzoEvent => {
  return {
    category: "login",
    object: "privacy_policy",
    action: "click",
    value: "privacy_policy",
  };
};

export const getTermsAndConditionsEvent = (): GenzoEvent => {
  return {
    category: "login",
    object: "terms_conditions",
    action: "click",
    value: "terms_conditions",
  };
};

export const getClickNotificationsBellEvent = (): GenzoEvent => {
  return {
    category: "notifications",
    object: "icon",
    action: "click",
    value: "bell_icon",
  };
};

export const getSeeAllNotificationsEvent = (): GenzoEvent => {
  return {
    category: "notifications",
    object: "button",
    action: "click",
    value: "see_all_notifications",
  };
};

export const getBrowseStoresEvent = (): GenzoEvent => {
  return {
    category: "notifications",
    object: "button",
    action: "click",
    value: "browse_stores",
  };
};

// BANK

export const getBankEmailEnteredEvent = (): GenzoEvent => {
  return {
    category: "withdraw",
    object: "add_email",
    action: "click",
    value: "next",
  };
};

export const getBankOtpVerifyEvent = (): GenzoEvent => {
  return {
    category: "withdraw",
    object: "otp",
    action: "click",
    value: "next",
  };
};

export const getBankOtpResendEvent = (): GenzoEvent => {
  return {
    category: "withdraw",
    object: "otp",
    action: "click",
    value: "resend_code",
  };
};

export const getBankAutoTransferEvent = (isAutoTransferEnabled): GenzoEvent => {
  return {
    category: "withdraw",
    object: "bank",
    action: "toggle_transfer",
    value: isAutoTransferEnabled ? "1" : "0",
  };
};

export const getAddBankSubmitEvent = (): GenzoEvent => {
  return {
    category: "withdraw",
    object: "bank",
    action: "click",
    value: "add_bank",
  };
};

export const getEditBankSubmitEvent = (): GenzoEvent => {
  return {
    category: "withdraw",
    object: "bank",
    action: "click",
    value: "edit_bank",
  };
};

export const getEditBankCancelEvent = (): GenzoEvent => {
  return {
    category: "wallet",
    object: "bank",
    action: "click",
    value: "edit_bank_cancel",
  };
};

export const getDeleteBankEvent = (): GenzoEvent => {
  return {
    category: "withdraw",
    object: "bank",
    action: "click",
    value: "delete_bank",
  };
};

export const getDeleteBankConfirmEvent = (): GenzoEvent => {
  return {
    category: "withdraw",
    object: "bank",
    action: "click",
    value: "confirm_delete_bank",
  };
};

export const getDeleteBankCancelEvent = (): GenzoEvent => {
  return {
    category: "wallet",
    object: "bank",
    action: "click",
    value: "delete_bank_cancel",
  };
};

//Manage Account Events
export const getEmailNotificationsEvent = (opt_type: string): GenzoEvent => {
  return {
    category: "account",
    object: "receive_email",
    action: "click",
    value: opt_type,
  };
};

// HEADER EVENTS
export const getSearchInputSearchEvent = (searchInput: string): GenzoEvent => {
  return {
    category: "search_bar",
    object: "search",
    action: "type",
    value: searchInput,
  };
};

// Refer and Earn Page
export const getSignUpButtonClickEvent = (action: string): GenzoEvent => {
  return {
    category: "referral",
    object: "referral",
    action: action,
    value: "sign_up",
  };
};

// Referral

export const getReferralCodeCopyEvent = (code: string): GenzoEvent => {
  return {
    category: "wallet",
    object: "referral",
    action: "click",
    value: code,
  };
};

// ClickHistory Details & Cashback Details

export const getClickHistoryDetailsEvent = (visitDate: string): GenzoEvent => {
  return {
    category: "wallet",
    object: "click_history",
    action: "click",
    value: visitDate,
  };
};

export const getCashbackDetailsEvent = (
  transactionTrackedDate: string
): GenzoEvent => {
  return {
    category: "wallet",
    object: "cashback_history",
    action: "click",
    value: transactionTrackedDate,
  };
};

export const getClickHistoryEmailUsEvent = (): GenzoEvent => {
  return {
    category: "wallet",
    object: "click_history",
    action: "email_us",
    value: "",
  };
};

// Search Input

export const getSearchResultClickEvent = (
  searchTerm: string,
  storeId: string,
  type: SearchResultType
): GenzoEvent => {
  const valueObj = {
    type: type,
    search_result: storeId,
    search_term: searchTerm,
  };

  return {
    category: "search_bar",
    object: "search",
    action: "search_result",
    value: JSON.stringify(valueObj),
  };
};

export const getSearchResultSeeAllEvent = (): GenzoEvent => {
  return {
    category: "search_bar",
    object: "search",
    action: "see_all",
    value: "",
  };
};

export const getAuthenticationFormClickEvent = (): GenzoEvent => {
  return {
    category: "login",
    object: "open",
    action: "modal_load",
    value: "",
  };
};

// Deal Coupon Modal

export const getDealCouponModalView = (
  location: string,
  deal: StoreDeal,
  collectionType: DealCollectionType | undefined
): GenzoEvent => {
  let category = "";
  let object = "";
  let valueObj: {
    deal: string;
    cashback: boolean;
    deal_collection?: string;
  } = {
    deal: deal.id,
    cashback: deal.store.featuredCashback ? true : false,
  };

  // determine category location base on pathname
  // and object since category and object is related
  switch (true) {
    case location.includes("/store"):
      category = "store";
      object = "code_get";
      break;
    case location.includes("/deals"):
      category = "deals";
      object = "all_deals";
      break;
    case location.includes("/coupons"):
      category = "coupons";
      object = "deals_coupons";
      break;
    default:
      category = "home";
      object = "all_deals";
      break;
  }

  // determine if modal originated from a deal collection
  // modify object and value accordingly
  if (collectionType) {
    valueObj.deal_collection = collectionType;
    object = "deal_collections";
  }

  return {
    category: category,
    object: object,
    action: "signed_in_modal_load",
    value: JSON.stringify(valueObj),
  };
};

const mapWithdrawalMethodValue = (
  method: WithdrawalMethod
): string | undefined => {
  let value: string | undefined;

  switch (method) {
    case WithdrawalMethod.GiftCards:
      value = "gift_card";
      break;
    case WithdrawalMethod.BankTransfer:
      value = "bank_transfer";
      break;
    case WithdrawalMethod.EWallet:
      value = "eWallet";
      break;
  }

  return value;
};

export const getWithdrawalMethodEvent = (
  method: WithdrawalMethod
): GenzoEvent => {
  return {
    category: "wallet",
    object: "withdrawal_method",
    action: "click",
    value: mapWithdrawalMethodValue(method),
  };
};

export const getSelectAmountGiftCardEvent = (
  isCustomAmountSelected: boolean
): GenzoEvent => {
  return {
    category: "withdraw",
    object: "giftcard",
    action: "select_amount",
    value: isCustomAmountSelected ? "custom" : "preset",
  };
};

export enum ProfileUpdateMissingInfo {
  email = "email",
  name = "name",
  both = "email and name",
}

export const getSubmitProfileUpdateEvent = (
  missingInfo: ProfileUpdateMissingInfo
): GenzoEvent => {
  return {
    category: "withdraw_giftcard",
    object: "withdraw_giftcard",
    action: "click",
    value: missingInfo,
  };
};

export const getProfileSuccessContinueEvent = (): GenzoEvent => {
  return {
    category: "withdraw",
    object: "profile_update",
    action: "click",
    value: "",
  };
};

export const getConfirmWithdrawalGiftCardEvent = (): GenzoEvent => {
  return {
    category: "withdraw",
    object: "giftcard",
    action: "confirm_withdrawal",
    value: "",
  };
};

export const getErrorMessageGiftCardEvent = (
  statusCode: string
): GenzoEvent => {
  return {
    category: "withdraw",
    object: "giftcard",
    action: "error",
    value: statusCode,
  };
};

export const getAddEmailGiftCardEvent = (): GenzoEvent => {
  return {
    category: "withdraw",
    object: "giftcard",
    action: "click",
    value: "next",
  };
};

export const getOtpVerifyGiftCardEvent = (): GenzoEvent => {
  return {
    category: "withdraw",
    object: "otp",
    action: "click",
    value: "next",
  };
};

export const getOtpResendGiftCardEvent = (): GenzoEvent => {
  return {
    category: "withdraw",
    object: "otp",
    action: "click",
    value: "resend_code",
  };
};

export const getCouponClickEvent = (
  deal: StoreDeal,
  index: number
): GenzoEvent => {
  const valueObj = {
    cashback: deal.store.featuredCashback ? true : false,
    has_code: !!deal.couponCode,
    deal: deal.id,
  };

  return {
    category: "coupons",
    object: "deals_coupons",
    action: formatZeroBasedIndex(index),
    value: JSON.stringify(valueObj),
  };
};

export const getCouponPageDealCouponModalClickEvent = (
  deal: StoreDeal,
  ctaType: GenzoDealCouponModalAction
): GenzoEvent => {
  const valueObj = {
    cashback: deal.store.featuredCashback ? true : false,
    deal: deal.id,
    click: ctaType,
  };

  return {
    category: "coupons",
    object: "deals_coupons",
    action: "modal_click",
    value: JSON.stringify(valueObj),
  };
};

export const getCouponPageCategoryClickEvent = (
  categoryId: string,
  index: number
): GenzoEvent => {
  return {
    category: "coupons",
    object: "category_list",
    action: formatZeroBasedIndex(index),
    value: categoryId,
  };
};

export const getLoginLaterEvent = () => {
  return {
    category: "extension",
    object: "log_in_later",
    action: "click",
    value: "tips",
  };
};

export const getExtensionReinstallClickEvent = (): GenzoEvent => {
  return {
    category: "extension",
    object: "reinstall",
    action: "click",
    value: "reinstall",
  };
};

export const getExtensionInstallClickEvent = (): GenzoEvent => {
  return {
    category: "extension",
    object: "chrome_install",
    action: "click",
    value: "sub_header",
  };
};

export const getWelcomeMessageBonusClickEvent = (
  payoutType: number
): GenzoEvent => {
  const payoutTypeMap = {
    2: "referral",
    3: "signup_campaign",
  };

  const valueObj = {
    payoutType: payoutTypeMap[payoutType],
  };

  return {
    category: "bonus",
    object: "bonus",
    action: "click",
    value: JSON.stringify(valueObj),
  };
};

export const getHowToUseCashbackClickEvent = (): GenzoEvent => {
  return {
    category: "bonus",
    object: "bonus",
    action: "click",
    value: "how_to_use_cashback",
  };
};

export const getPhoneVerificationIntroductionNextClickEvent = (
  genzoAction: string,
  genzoValue?: any
): GenzoEvent => {
  return {
    category: "add_phone_number",
    object: "update_phone_number",
    action: genzoAction,
    value: genzoValue ? JSON.stringify(genzoValue) : "",
  };
};

export const getPhoneVerificationFormNextClickEvent = (): GenzoEvent => {
  return {
    category: "add_phone_number",
    object: "add_phone_number",
    action: "click",
    value: "",
  };
};

export const getPhoneVerificationValidateOTPClickEvent = (): GenzoEvent => {
  return {
    category: "add_phone_number",
    object: "otp",
    action: "click",
    value: "",
  };
};

export const getPhoneVerificationValidateOTPSuccessEvent = (
  genzoAction: string
): GenzoEvent => {
  return {
    category: "add_phone_number",
    object: "phone_number_update_success",
    action: "click",
    value: genzoAction,
  };
};

export const getPhoneVerificationRetryClickEvent = (): GenzoEvent => {
  return {
    category: "add_phone_number",
    object: "phone_number_update_retry",
    action: "click",
    value: "",
  };
};
