import { API_HOST, parseResponseBody } from "./apiHelpers";

export const getSiteConfigData = (
  siteCode = "US",
  locale = "en",
  appType = "desktop"
): Promise<SiteConfig> => {
  const url = `${API_HOST}/site-config/${siteCode}?language=${locale}&appType=${appType}`;
  return fetch(url)
    .then((r: Response) => r.json())
    .then((body: SiteConfigResponse) =>
      parseResponseBody<SiteConfigResponse, SiteConfig>(body)
    )
    .catch((e) => {
      console.error(e);
      return null;
    });
};
