interface Sites {
  [key: string]: {
    domain: {
      development: string;
      staging: string;
      production: string;
    };
    defaultLocale: string;
    siteName: string;
  };
}

export const sites: Sites = {
  AE: {
    domain: {
      development: "ae.shopcashdev.com",
      staging: "ae.shopcashstaging.com",
      production: "shopcash.ae",
    },
    defaultLocale: "en",
    siteName: "Shopcash UAE",
  },
  SA: {
    domain: {
      development: "sa.shopcashdev.com",
      staging: "sa.shopcashstaging.com",
      production: "shopcash.sa",
    },
    defaultLocale: "ar",
    siteName: "Shopcash KSA",
  },
  EG: {
    domain: {
      development: "eg.shopcashdev.com",
      staging: "eg.shopcashstaging.com",
      production: "eg.shopcash.com",
    },
    defaultLocale: "ar",
    siteName: "Shopcash Egypt",
  },
  KW: {
    domain: {
      development: "kw.shopcashdev.com",
      staging: "kw.shopcashstaging.com",
      production: "kw.shopcash.com",
    },
    defaultLocale: "ar",
    siteName: "Shopcash Kuwait",
  },
  // OM: {
  //   domain: {
  //     development: "om.shopcashdev.com",
  //     staging: "om.shopcashstaging.com",
  //     production: "om.shopcash.com",
  //   },
  //   defaultLocale: "ar",
  //   siteName: "Shopcash Oman"
  // },
  // QA: {
  //   domain: {
  //     development: "qa.shopcashdev.com",
  //     staging: "qa.shopcashstaging.com",
  //     production: "qa.shopcash.com",
  //   },
  //   defaultLocale: "ar",
  //   siteName: "Shopcash Qatar"
  // },
  BH: {
    domain: {
      development: "bh.shopcashdev.com",
      staging: "bh.shopcashstaging.com",
      production: "bh.shopcash.com",
    },
    defaultLocale: "ar",
    siteName: "Shopcash Bahrain",
  },
  US: {
    domain: {
      development: "shopcashdev.com",
      staging: "shopcashstaging.com",
      production: "shopcash.com",
    },
    defaultLocale: "en",
    siteName: "Shopcash",
  },
};
