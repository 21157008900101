import Button from "@components/Button";
import FlapIcon from "@icons/flip.svg";
import { VariantButton } from "@shopcashTypes/variantButton";
import clsx from "clsx";
import styles from "./ButtonWithPreview.module.scss";

interface ButtonWithPreviewProps {
  children: React.ReactNode;
  previewText: string;
  large?: boolean;
  loading?: boolean;
  onClick?: () => void;
}

const ButtonWithPreview: React.FC<ButtonWithPreviewProps> = ({
  children,
  previewText,
  large,
  loading,
  onClick,
}) => {
  const handleOnButtonClick = (loading: boolean): void => {
    if (loading) return;
    if (onClick) onClick();
  };

  return (
    <div
      className={clsx(styles.container, large && styles.large)}
      onClick={() => handleOnButtonClick(loading)}
    >
      <Button
        variant={VariantButton.Primary}
        large={large}
        loading={loading}
        textAlignStart={true}
        fullWidth={true}
      >
        {children}
      </Button>
      <div className={styles.preview}>{previewText}</div>
      <div className={styles.foldIcon}>
        <FlapIcon />
      </div>
    </div>
  );
};

export default ButtonWithPreview;
