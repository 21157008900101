export enum VariantButton {
  Primary = "primary",
  Secondary = "secondary",
  RoundedSecondary = "roundedSecondary",
  SecondaryWhite = "secondaryWhite",
  OutlinePrimary = "outlinePrimary",
  OutlineSecondary = "outlineSecondary",
  RoundedOutlineSecondary = "roundedOutlineSecondary",
  Facebook = "facebook",
  Google = "google",
  EmailAuth = "emailAuth",
  PrimaryFolded = "primaryFolded",
}
