import LoadingIcon from "@icons/loadingIcon.svg";
import styles from "./LoadingIndicator.module.scss";

const LoadingIndicator: React.FC = () => {
  return (
    <div className={styles.loadingIconContainer}>
      <div className={styles.loadingIcon}>
        <LoadingIcon />
      </div>
    </div>
  );
};

export default LoadingIndicator;
